<form [formGroup]="selectArticle" (ngSubmit)="save()">
  <div class="row">
    <div class="col-100">
      <mat-form-field class="w100p">
        <mat-label>Article number</mat-label>
        <mat-select matNativeControl formControlName="selectedArticle" name="article_number">
          <mat-option *ngFor="let number of numbers; index as i" [value]="number">
            {{number.article_number}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="this.f.selectedArticle.hasError('required') && this.submitted">
          This field is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-100">
      <mat-form-field class="w100p">
        <mat-label>Country</mat-label>
        <mat-select formControlName="timezone">
          <mat-option value="Europe/Sofia">Bulgaria</mat-option>
          <mat-option value="America/Mexico_City">Mexico</mat-option>
          <mat-option value="Europe/Zurich">Switzerland</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-100">
      <mat-form-field class="w100p">
        <mat-label>Personal number</mat-label>
        <input matInput numeric numericType="number" formControlName="personalNumber" name="personalNumber">
        <mat-error *ngIf="this.f.personalNumber.hasError('required') && this.submitted">
          This field is required
        </mat-error>
        <mat-error *ngIf="this.f.personalNumber.hasError('maxlength') && this.submitted">
          This field max length is  10 numbers
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-100">
      <mat-form-field class="w100p">
        <mat-label>Prod order</mat-label>
        <input matInput numeric numericType="number" name="prodOrder" formControlName="prodOrder">
        <mat-error *ngIf="this.f.prodOrder.hasError('required') && this.submitted">
          This field is required
        </mat-error>
        <mat-error *ngIf="this.f.prodOrder.hasError('maxlength') && this.submitted">
          This field max length is  10 numbers
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row" *ngIf="this.f.selectedArticle.value['with_order_number'] === 1">
    <div class="col-100">
      <mat-form-field class="w100p">
        <mat-label>Order Number</mat-label>
        <input matInput formControlName="order_number" name="order_number">
        <mat-error *ngIf="this.f.order_number.hasError('required') && this.submitted">
          This field is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row" *ngIf="this.f.selectedArticle.value['with_plant'] === 1">
    <div class="col-100">
      <mat-form-field class="w100p">
        <mat-label>Plant</mat-label>
        <input matInput formControlName="plant" name="plant">
        <mat-error *ngIf="this.f.plant.hasError('required') && this.submitted">
          This field is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row" *ngIf="this.f.selectedArticle.value['with_line'] === 1">
    <div class="col-100">
      <mat-form-field class="w100p">
        <mat-label>Line</mat-label>
        <input matInput formControlName="line" name="line">
        <mat-error *ngIf="this.f.line.hasError('required') && this.submitted">
          This field is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>




  <div class="row text-center">
    <button mat-flat-button type="submit" color="accent" style="margin-right: 10px;">Enter</button>
    <button mat-flat-button color="primary" mat-dialog-close type="button">Close</button>
  </div>

</form>
