import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../user.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SelectArticleComponent } from '../dmc-check/select-article/select-article.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(public dialog: MatDialog,public userService:UserService, private router: Router,private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  openDialog() {
    const dialogRef = this.dialog.open(EnterPasswordDialog, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe(result => {
      var data = {email:result.email,password:result.password};
      this.userService.login(data).subscribe(resp=>{
        console.log(resp)
        if(resp['error']){
          this.openSnackBar(resp['error'],null);
        }

        if(resp['token']){
          this.userService.setToken(resp['token']);
          this.router.navigate(['/dashboard']);
        }



      })

    });
  }

  selectArticle() {
    const dialogRef = this.dialog.open(SelectArticleComponent, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

}

@Component({
  selector: 'enter-password-dialog',
  templateUrl: 'password.html',
})
export class EnterPasswordDialog {
  password:string;
  email:string;

  constructor(public dialogRef: MatDialogRef<EnterPasswordDialog>) {

  }

  onSubmit(){
    var data = {email:this.email,password:this.password};
    this.dialogRef.close(data);
  }
}
