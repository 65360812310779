<h2 mat-dialog-title>Admin Area Login</h2>
<mat-dialog-content class="mat-typography">
    <form (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="col-100">
                <mat-form-field class="w100p">
                    <mat-label>Enter email</mat-label>
                    <input matInput [(ngModel)]="email" name="email" type="email">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-100">
                <mat-form-field class="w100p">
                    <mat-label>Enter password</mat-label>
                    <input matInput [(ngModel)]="password" name="password" type="password">
                </mat-form-field>
            </div>
        </div>
        
        <div class="row text-center">    <button mat-flat-button color="primary" type="submit" >Login</button></div>
    
    </form>
</mat-dialog-content>