import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DmcCheckService } from '../dmc-check.service';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-select-article',
  templateUrl: './select-article.component.html',
  styleUrls: ['./select-article.component.scss']
})
export class SelectArticleComponent implements OnInit {
  numbers: any;
  selectArticle: FormGroup;
  submitted: number = 0;
  scanned_today:number = 0;

  constructor(private dmcService: DmcCheckService, public dialogRef: MatDialogRef<SelectArticleComponent>, private formBuilder: FormBuilder,private router: Router) { }
  @Output() numberEvent = new EventEmitter<string>();

  ngOnInit(): void {
    this.selectArticle = this.formBuilder.group({
      timezone:['Europe/Sofia',Validators.required],
      selectedArticle: ['', Validators.required],
      selectedNumber: ['', Validators.required],
      plant: [''],
      line: [''],
      order_number: [''],
      personalNumber: ['',Validators.maxLength(10)],
      prodOrder: ['',Validators.maxLength(10)],

    });

    this.dmcService.getArticleNumbers().subscribe(resp => {

      if (resp) {
        const mapped = Object.keys(resp).map(key => (resp[key]));
        console.log(mapped)
        this.numbers = mapped;
      }
    });

    this.selectArticle.get('selectedArticle').valueChanges.subscribe(val =>{
      this.selectArticle.get('selectedNumber').setValue(val.id);
      this.scanned_today = val.scanned_today;
      if(val['with_line'] === 1){
        this.selectArticle.get('line').setValidators([Validators.required]);
      }else{
        this.selectArticle.get('line').setValidators([]);
      }
      this.selectArticle.get('line').updateValueAndValidity();

      if(val['with_plant'] === 1){
        this.selectArticle.get('plant').setValidators([Validators.required]);
      }else{
        this.selectArticle.get('plant').setValidators([]);
      }
      this.selectArticle.get('plant').updateValueAndValidity();

      if(val['with_order_number'] === 1){
        this.selectArticle.get('order_number').setValidators([Validators.required]);
      }else{
        this.selectArticle.get('order_number').setValidators([]);
      }
      this.selectArticle.get('order_number').updateValueAndValidity();
    });
  }

  get f() { return this.selectArticle.controls; }

  save() {
    this.submitted = 1;
    if (this.selectArticle.valid) {
      this.dmcService.setProgramNumber(this.f.selectedNumber.value);
      this.dmcService.setTimezone(this.f.timezone.value);
      this.dmcService.setPersonalNumber(this.f.personalNumber.value);
      this.dmcService.setProdOrder(this.f.prodOrder.value);
      this.dmcService.setPlant(this.f.plant.value);
      this.dmcService.setOrderNumber(this.f.order_number.value);
      this.dmcService.setScannedToday(this.scanned_today);
      this.dmcService.setLine(this.f.line.value);
      this.router.navigate(['/dmc-check']);
      this.dialogRef.close();
    }
  }

}
