import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'; // CLI imports router
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './guards/auth-guard.service';

const routes: Routes = [
  { path: '', component: LoginComponent , data: {title: 'DMC Checker'}},
  {
    path: 'dashboard',
    loadChildren: './dashboard/dashboard.module#DashboardModule',
    canActivate: [AuthGuard] 
  },
  {
    path: 'programs',
    loadChildren: './programs/programs.module#ProgramsModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'dmc-check',
    loadChildren: './dmc-check/dmc-check.module#DmcCheckModule',
    // canActivate: [AuthGuard]
  },
  {
    path: 'users',
    loadChildren: './users/users.module#UsersModule',
    canActivate: [AuthGuard]
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }