import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatirialModule } from './matirial/matirial.module';
import { LoginComponent, EnterPasswordDialog } from './login/login.component';
import { HttpClientModule } from '@angular/common/http';
import { AuthGuard } from './guards/auth-guard.service';
import { ErrorSnackbarComponent } from './error-snackbar/error-snackbar.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    EnterPasswordDialog,
    ErrorSnackbarComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatirialModule,
    HttpClientModule
  ],
  providers: [AuthGuard,{provide: MAT_DATE_LOCALE, useValue: 'bg-BG'}],
  bootstrap: [AppComponent]
})
export class AppModule { }
