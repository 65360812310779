import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DmcCheckService {
    personalNumber:number;
    programNumber:number;
    prodOrder:number;
    plant:string;
    order_number:string;
    line:string;
    timezone:string;
    scanned_today:number;

  constructor(private http: HttpClient) { }

  setPersonalNumber(val){
    this.personalNumber = val;
  }

  setProgramNumber(val){
    this.programNumber = val;
  }

  setProdOrder(val){
    this.prodOrder = val;
  }

  setPlant(val){
    this.plant = val;
  }

  setOrderNumber(val){
    this.order_number = val;
  }

  setLine(val){
    this.line = val;
  }

  setTimezone(val){
    this.timezone = val;
  }

  setScannedToday(val){
    this.scanned_today = val;
  }

  getScannedToday(){
    return this.scanned_today;
  }

  getTimezone(){
    return this.timezone;
  }

  getPlant(){
    return this.plant;
  }

  getOrderNUmber(){
    return this.order_number;
  }

  getLine(){
    return this.line;
  }

  getProdOrder(){
    return this.prodOrder;
  }

  getPersonalNumber(){
    return this.personalNumber;
  }

  getProgramNumber(){
    return this.programNumber;
  }

  save(data) {
    return this.http.post(environment.apiUrl + 'scanDmc', data);
  }

  all(page,data) {
    return this.http.get(environment.apiUrl + 'scannedDmcs?page=' + page + '&token=' + localStorage.getItem('TOKEN'),{params:data});
  }


  scrapePart(data) {
    return this.http.post(environment.apiUrl + 'scrapePart', data);
  }


  getArticleNumbers() {
    return this.http.get(environment.apiUrl + 'articleNumbers');
  }

  nextNumber(id) {
    return this.http.get<number>(environment.apiUrl + 'getNextNumber/'+id+"?token=" + localStorage.getItem('TOKEN'));
  }

  resetScanCounter(id){
    return this.http.get(environment.apiUrl + 'resetScanCounter/'+id);
  }

  getProgramScannedToday(id){
    return this.http.post(environment.apiUrl + 'getProgramScannedToday/'+id,[]);
  }
}
