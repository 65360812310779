import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http:HttpClient) { }

  setToken(token: string): void {
    localStorage.removeItem("TOKEN")
    localStorage.setItem("TOKEN", token);
  }

  isLogged():boolean {
    return localStorage.getItem("TOKEN") != null;
  }

  login(data) {
    return this.http.post(environment.apiUrl + 'authenticate',data);
  }
} 
