<div class="flex-column-container ">
    <div class="box">
        <div class="v-center text-center">

                    <h1>DMC Checker</h1>
        <button mat-flat-button color="primary" (click)="selectArticle()" style="margin-right: 20px;">DMC Check </button>
        <!-- routerLink="dmc-check" -->
        <button mat-flat-button color="accent" class="mrg-left-10" (click)="openDialog()">Admin</button>
        </div>


    </div>


</div>